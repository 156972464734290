import React, { useState } from "react";
import { FaHome, FaCog, FaGift, FaUser, FaSignOutAlt, FaSpinner } from "react-icons/fa";
import useDashboardLogic from "../hooks/useDashboardLogic";
import "./DashboardNav.css";

const DashboardNav = ({ logout }) => {
  const { navigateTo } = useDashboardLogic();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = () => {
    setIsLoggingOut(true);
    setTimeout(() => {
      logout();
      setIsLoggingOut(false);
    }, 2000);
  };

  return (
    <div>
      {isLoggingOut && (
        <div className="overlay-logout">
          <div className="logout-loader">
            <FaSpinner className="spinner-icon" />
            <p>Logging Out...</p>
          </div>
        </div>
      )}

      <div className="dashboard-nav">
        <header className="nav-desktop">
          <ul className="nav-list">
            <li onClick={() => navigateTo("home")}>
              <FaHome className="nav-icon" />
              <span>Home</span>
            </li>
            <li onClick={() => navigateTo("settings")}>
              <FaCog className="nav-icon" />
              <span>Settings</span>
            </li>
            <li onClick={() => navigateTo("airdrop")}>
              <FaGift className="nav-icon" />
              <span>Airdrop</span>
            </li>
            <li onClick={() => navigateTo("account")}>
              <FaUser className="nav-icon" />
              <span>Account</span>
            </li>
            <li onClick={handleLogout}>
              <FaSignOutAlt className="nav-icon logout-icon" />
              <span>Logout</span>
            </li>
          </ul>
        </header>

        <nav className="nav-mobile">
          <ul className="nav-list-mobile">
            <li onClick={() => navigateTo("home")}>
              <FaHome className="nav-icon-mobile" />
              <span>Home</span>
            </li>
            <li onClick={() => navigateTo("settings")}>
              <FaCog className="nav-icon-mobile" />
              <span>Settings</span>
            </li>
            <li onClick={() => navigateTo("airdrop")}>
              <FaGift className="nav-icon-mobile" />
              <span>Airdrop</span>
            </li>
            <li onClick={() => navigateTo("account")}>
              <FaUser className="nav-icon-mobile" />
              <span>Account</span>
            </li>
            <li onClick={handleLogout}>
              <FaSignOutAlt className="nav-icon-mobile logout-icon" />
              <span>Logout</span>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default DashboardNav;
