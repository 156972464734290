import React, { useState, useContext } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase"; // Import Firebase auth instance
import { AuthContext } from "../contexts/AuthContext";
import PasswordReset from "./PasswordReset";
import Notification from "./Notification"; // Import Notification component
import "./Auth.css";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Icons for hide/unhide

const Login = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notification, setNotification] = useState(null); // For notifications
  const [isLoading, setIsLoading] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Toggle password visibility

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setNotification(null);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await userCredential.user.getIdToken(); // Get Firebase ID token
      await login(email, password, idToken); // Update context with login
      setNotification({ message: "Login successful!", type: "success" }); // Success notification
    } catch (err) {
      setNotification({ message: err.message || "Invalid email or password. Please try again.", type: "error" }); // Error notification
    } finally {
      setIsLoading(false);
    }
  };

  if (isResetPassword) {
    return <PasswordReset onBack={() => setIsResetPassword(false)} />;
  }

  return (
    <div className="auth-container">
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)} // Dismiss notification
        />
      )}
      <form onSubmit={handleSubmit} className="auth-form">
        <h2>Login</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"} // Toggle visibility
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <span
            className="toggle-password"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Logging in..." : "Login"}
        </button>
        <p>
          Forgot your password?{" "}
          <span className="auth-link" onClick={() => setIsResetPassword(true)}>
            Reset Password
          </span>
        </p>
      </form>
    </div>
  );
};

export default Login;
