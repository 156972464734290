import React from "react";
import { motion } from "framer-motion";
import "./Mining.css";

const MiningHero = () => {
  return (
    <section className="mining-hero">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="hero-content"
      >
        <h1 className="hero-title">Mine PotusSol Tokens</h1>
        <p className="hero-description">
          Join the decentralized revolution by mining PotusSol tokens! Connect your wallet to start
          earning rewards and contributing to the network.
        </p>
      </motion.div>
    </section>
  );
};

export default MiningHero;
