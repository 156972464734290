import React from "react";
import { motion } from "framer-motion";
import {
  FaTelegramPlane,
  FaGithub,
  FaBitcoin,
  FaEthereum,
  FaFacebook,
  FaLinkedin,
} from "react-icons/fa";
import { SiBinance, SiSolana } from "react-icons/si";
import { RiMastercardFill } from "react-icons/ri";
import { FiX } from "react-icons/fi";
import "./FooterSocial.css";

const FooterSocial = () => (
  <div className="footer-social">
    <h3>Follow Us</h3>
    <div className="social-icons">
      <motion.a
        href="https://x.com"
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.2, rotate: 10 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <FiX className="social-icon twitter" />
      </motion.a>
      <motion.a
        href="https://telegram.org"
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.2, rotate: 10 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <FaTelegramPlane className="social-icon telegram" />
      </motion.a>
      <motion.a
        href="https://github.com"
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.2, rotate: 10 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <FaGithub className="social-icon github" />
      </motion.a>
      <motion.a
        href="https://facebook.com"
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.2, rotate: 10 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <FaFacebook className="social-icon facebook" />
      </motion.a>
      <motion.a
        href="https://linkedin.com"
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.2, rotate: 10 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <FaLinkedin className="social-icon linkedin" />
      </motion.a>
    </div>

    <h3>Partners</h3>
    <div className="sponsorship-icons">
      <motion.div
        className="partner-icon mastercard"
        whileHover={{ scale: 1.2 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <RiMastercardFill className="sponsorship-icon" />
      </motion.div>
      <motion.div
        className="partner-icon binance"
        whileHover={{ scale: 1.2 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <SiBinance className="sponsorship-icon" />
      </motion.div>
      <motion.div
        className="partner-icon bitcoin"
        whileHover={{ scale: 1.2 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <FaBitcoin className="sponsorship-icon" />
      </motion.div>
      <motion.div
        className="partner-icon ethereum"
        whileHover={{ scale: 1.2 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <FaEthereum className="sponsorship-icon" />
      </motion.div>
      <motion.div
        className="partner-icon solana"
        whileHover={{ scale: 1.2 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <SiSolana className="sponsorship-icon" />
      </motion.div>
    </div>
  </div>
);

export default FooterSocial;
