import React, { useEffect } from "react";
import "./Notification.css";

const Notification = ({ message, type, onClose = () => {} }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose(); // Automatically close the notification after 5 seconds
    }, 5000);

    return () => clearTimeout(timer); // Clear timeout on unmount
  }, [onClose]);

  return (
    <div className={`notification ${type}`}>
      <span>{message}</span>
    </div>
  );
};

export default Notification;
