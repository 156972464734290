import React from "react";
import InvestWallet from "./InvestWallet";
import "./Home.css";

const DashboardHome = () => {
  return (
    <div className="dashboard-home">
      <InvestWallet />
    </div>
  );
};

export default DashboardHome;
