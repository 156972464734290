const InvestmentDetailsData = {
    title: "Why Invest in PotusSol?",
    intro:
      "PotusSol is a revolutionary blockchain-based ecosystem aimed at empowering communities through decentralization and innovation. By investing, you become part of a rapidly growing ecosystem and gain access to:",
    benefits: [
      {
        icon: "FaHandshake",
        text: "Profit sharing from transaction fees and staking rewards.",
      },
      {
        icon: "FaGavel",
        text: "Voting power to influence key decisions.",
      },
      {
        icon: "FaEye",
        text: "Transparency with access to real-time performance dashboards.",
      },
      {
        icon: "FaMoneyCheck",
        text: "Regular dividends distributed based on profits.",
      },
    ],
    keyFeaturesTitle: "Key Features",
    features: [
      {
        icon: "FaGavel",
        title: "Governance Tokens:",
        description: "Influence decisions proportionally to your stake.",
      },
      {
        icon: "FaCoins",
        title: "Profit Sharing:",
        description: "Earn from staking, transaction fees, and ecosystem growth.",
      },
      {
        icon: "FaChartLine",
        title: "Transparency:",
        description: "Access detailed analytics on token performance.",
      },
      {
        icon: "FaWallet",
        title: "Dividends:",
        description: "Periodic payouts to investor wallets.",
      },
    ],
    summary:
      "By investing in PotusSol, you join a forward-thinking ecosystem that prioritizes community growth, financial rewards, and innovative blockchain solutions.",
  };
  
  export default InvestmentDetailsData;
  