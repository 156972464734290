import React, { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import DashboardNav from "./Nav/Nav";
import "./Dashboard.css";

const Dashboard = () => {
  const { isAuthenticated, logout, isLoading } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) return;

    if (!isAuthenticated) {
      // Redirect to login if the user is not authenticated
      navigate("/");
    } else {
      const savedPath = localStorage.getItem("lastVisitedPath") || "/dashboard/home";
      if (location.pathname === "/dashboard") {
        // Redirect to the last visited path or home if dashboard root is accessed
        navigate(savedPath);
      }
    }
  }, [isAuthenticated, isLoading, navigate, location.pathname]);

  useEffect(() => {
    if (isAuthenticated) {
      // Save the current path for persistence
      localStorage.setItem("lastVisitedPath", location.pathname);
    }
  }, [location.pathname, isAuthenticated]);

  if (isLoading) {
    return <div>Loading...</div>; // Optional loader
  }

  return (
    <div className="dashboard">
      {/* Navigation */}
      <DashboardNav logout={logout} />

      {/* Nested Routes Content */}
      <div className="dashboard-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
