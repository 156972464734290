import React from "react";
import { motion } from "framer-motion";
import {
  FaLightbulb,
  FaShieldAlt,
  FaUsers,
  FaProjectDiagram,
  FaTools,
  FaExpand,
} from "react-icons/fa";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./About.css";

const About = () => {
  return (
    <>
      <Header />
      <div className="about">
        {/* Hero Section */}
        <section className="about-hero">
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="hero-content"
          >
            <h1 className="hero-title">About PotusSol</h1>
            <p className="hero-description">
              Empowering communities with blockchain innovation, decentralization, and limitless possibilities.
            </p>
          </motion.div>
        </section>

        {/* Key Values Section */}
        <section className="key-values">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <h2 className="section-title">Our Core Values</h2>
            <div className="values-grid">
              <div className="value-card">
                <FaLightbulb className="value-icon innovation-icon" />
                <h3>Innovation</h3>
                <p>Empowering communities with cutting-edge blockchain solutions and technology.</p>
              </div>
              <div className="value-card">
                <FaShieldAlt className="value-icon security-icon" />
                <h3>Security</h3>
                <p>Providing a secure ecosystem to protect your assets and ensure trust.</p>
              </div>
              <div className="value-card">
                <FaUsers className="value-icon community-icon" />
                <h3>Community</h3>
                <p>Building a global network of thinkers, innovators, and decentralized minds.</p>
              </div>
            </div>
          </motion.div>
        </section>

        {/* Features Section */}
        <section className="features">
          <h2 className="section-title">What Makes Us Different?</h2>
          <div className="features-grid">
            <motion.div className="feature-card" whileHover={{ scale: 1.05 }}>
              <FaProjectDiagram className="feature-icon ecosystem-icon" />
              <h3>Decentralized Ecosystem</h3>
              <p>A fully decentralized system ensuring transparency, trust, and autonomy.</p>
            </motion.div>
            <motion.div className="feature-card" whileHover={{ scale: 1.05 }}>
              <FaTools className="feature-icon tools-icon" />
              <h3>Next-Gen Tools</h3>
              <p>Innovative tools designed to integrate seamlessly with blockchain platforms.</p>
            </motion.div>
            <motion.div className="feature-card" whileHover={{ scale: 1.05 }}>
              <FaExpand className="feature-icon scalability-icon" />
              <h3>Future-Ready</h3>
              <p>Scalable for millions of transactions, ensuring sustainability and growth.</p>
            </motion.div>
          </div>
        </section>

        {/* Call-to-Action */}
        <section className="cta-section">
          <h2 className="cta-title">Be Part of the Future</h2>
          <p className="cta-description">
            Join PotusSol and help shape the future of blockchain and Web3. Together, we can create a decentralized tomorrow.
          </p>
          <motion.button
            whileHover={{ scale: 1.1, boxShadow: "0px 0px 15px #61dafb" }}
            className="cta-button"
          >
            Get Started
          </motion.button>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default About;
