import React from "react";
import { motion } from "framer-motion";

const FooterBottom = ({ currentYear }) => (
  <motion.div
    className="footer-bottom"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 1, delay: 0.5 }}
  >
    <p>&copy; {currentYear} PotusSol. All rights reserved.</p>
  </motion.div>
);

export default FooterBottom;
