export const FaqData = [
    {
      question: "What is PotusSol?",
      answer:
        "PotusSol is a cutting-edge decentralized platform designed to empower communities through blockchain technology. It combines innovation, security, and transparency to create a sustainable ecosystem for Web3 enthusiasts.",
    },
    {
      question: "How can I buy PotusSol tokens?",
      answer:
        "PotusSol tokens can be purchased on supported decentralized exchanges (DEXs). Simply connect your wallet, search for 'POTUSSOL' using our contract address, and proceed with the transaction. Visit our Tokenomics page for the official contract address and step-by-step instructions.",
    },
    {
      question: "What are the benefits of staking PotusSol tokens?",
      answer:
        "By staking PotusSol tokens, you earn passive rewards while contributing to network security. Staking also unlocks additional benefits such as governance rights, priority access to upcoming features, and exclusive community rewards.",
    },
    {
      question: "How do I mine PotusSol tokens?",
      answer:
        "To mine PotusSol tokens, connect your wallet to our mining platform and follow the guided steps. Mining rewards are distributed based on your participation and the total mining pool contribution. Stay tuned for updates on when mining rewards will go live!",
    },
    {
      question: "Is PotusSol secure?",
      answer:
        "Yes, PotusSol is built using audited smart contracts and industry-standard security protocols. We implement rigorous measures to protect user assets and ensure the integrity of our decentralized ecosystem.",
    },
    {
      question: "What fees are associated with PotusSol transactions?",
      answer:
        "A 2% transaction fee is applied to every transfer, which goes directly to our treasury wallet to support development, community initiatives, and ecosystem growth. The fee is adjustable but capped at 10% to maintain fairness.",
    },
    {
      question: "Can I burn PotusSol tokens?",
      answer:
        "Yes, you can burn PotusSol tokens to reduce the circulating supply. This is a voluntary feature that allows users to increase scarcity and potentially enhance token value over time.",
    },
    {
      question: "How can I connect my wallet to PotusSol?",
      answer:
        "You can connect your wallet by clicking the 'Connect Wallet' button on our dashboard. PotusSol supports MetaMask, WalletConnect, and other popular wallets. Ensure your wallet is configured to the appropriate network to avoid errors.",
    },
    {
      question: "What is the roadmap for PotusSol?",
      answer:
        "Our roadmap includes key milestones such as platform launch, token listing, staking and mining rollout, NFT integration, multi-chain expansion, and strategic partnerships. Visit our Roadmap page for detailed updates.",
    },
    {
      question: "How can I participate in PotusSol governance?",
      answer:
        "Token holders can participate in governance by staking their tokens and voting on proposals that shape the future of the PotusSol ecosystem. Governance features will be rolled out in a future update.",
    },
    {
      question: "What happens if I lose access to my wallet?",
      answer:
        "PotusSol does not have access to your wallet or private keys. Please ensure you back up your recovery phrase securely. If you lose access to your wallet, refer to your wallet provider's recovery options.",
    },
    {
      question: "Where can I learn more about PotusSol and join the community?",
      answer:
        "Join our vibrant community on platforms like Twitter, Telegram, and Discord. Stay updated with announcements, participate in discussions, and connect with like-minded individuals. Visit the footer of our site for direct links to our social channels.",
    },
  ];
  