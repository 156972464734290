import React, { useState, useEffect } from "react";
import axios from "axios";
import Web3 from "web3";
import { FaWallet, FaEthereum, FaCopy } from "react-icons/fa";
import Notification from "../Notification";
import "./InvestWallet.css";

const InvestWallet = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [connectionStatus, setConnectionStatus] = useState("disconnected");
  const [balance, setBalance] = useState("");
  const [notifications, setNotifications] = useState([]); // Manage multiple notifications

  const addNotification = (message, type) => {
    const id = Date.now(); // Unique ID for each notification
    setNotifications((prev) => [...prev, { id, message, type }]);
  };

  const removeNotification = (id) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  const attachWalletToUser = async (wallet) => {
    try {
      const userUid = localStorage.getItem("userUid");
      if (!userUid) {
        throw new Error("User is not authenticated. UID not found.");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/wallet/connect`,
        { walletAddress: wallet, uid: userUid }
      );

      addNotification("Wallet successfully attached to your account.", "success");
    } catch (error) {
      addNotification("Failed to attach wallet to user.", "error");
    }
  };

  const fetchWalletBalance = async (wallet) => {
    try {
      const web3 = new Web3(process.env.REACT_APP_LOCAL_RPC_URL);
      const balanceInWei = await web3.eth.getBalance(wallet);
      const balanceInEther = web3.utils.fromWei(balanceInWei, "ether");
      setBalance(parseFloat(balanceInEther).toFixed(4));
    } catch (error) {
      addNotification("Failed to fetch wallet balance.", "error");
    }
  };

  const connectWallet = async () => {
    setConnectionStatus("connecting");
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        const wallet = accounts[0];
        setWalletAddress(wallet);
        localStorage.setItem("userWallet", wallet);

        await attachWalletToUser(wallet);
        await fetchWalletBalance(wallet);

        setConnectionStatus("connected");
        addNotification("Wallet connected successfully!", "success");
      } else {
        throw new Error("No Ethereum provider found. Install MetaMask or similar wallet.");
      }
    } catch (error) {
      addNotification(error.message || "Failed to connect wallet. Please try again.", "error");
      setConnectionStatus("disconnected");
    }
  };

  useEffect(() => {
    const savedWallet = localStorage.getItem("userWallet");
    if (savedWallet) {
      setWalletAddress(savedWallet);
      fetchWalletBalance(savedWallet);
      setConnectionStatus("connected");
    }
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(walletAddress);
    addNotification("Wallet address copied to clipboard!", "success");
  };

  const formatWalletAddress = (address) => {
    if (!address) return "";
    const first = address.slice(0, 6);
    const last = address.slice(-5);
    return `${first}***${last}`;
  };

  return (
    <div className="invest-wallet">
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          message={notification.message}
          type={notification.type}
          onClose={() => removeNotification(notification.id)}
        />
      ))}

      <div className="wallet-status">
        <span
          className={`status-dot ${connectionStatus === "connected" ? "green" : "red"}`}
        ></span>
        <span className="status-text">
          {connectionStatus === "connected" ? "Wallet Active" : "No Active Wallet"}
        </span>
      </div>

      <h2>
        <FaWallet className="wallet-icon" /> Wallet
      </h2>

      {walletAddress ? (
        <div className="wallet-info">
          <p className="wallet-address">
            Address: {formatWalletAddress(walletAddress)}
            <button onClick={copyToClipboard} className="copy-btn">
              <FaCopy />
            </button>
          </p>
          <p className="wallet-balance">
            <FaEthereum className="eth-icon" />
            {balance || "Loading..."} ETH
          </p>
        </div>
      ) : (
        <p className="wallet-status">
          {connectionStatus === "connecting" ? "Connecting..." : "Disconnected"}
        </p>
      )}

      {connectionStatus !== "connected" && (
        <button onClick={connectWallet} className="connect-wallet-btn">
          Connect Wallet
        </button>
      )}
    </div>
  );
};

export default InvestWallet;
