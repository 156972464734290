import React from "react";
import { motion } from "framer-motion";
import "./Roadmap.css";

const Roadmap = () => {
  const roadmapData = [
    {
      phase: "Phase 1: Launch",
      description: "Token creation, website launch, and community building.",
      icon: "🚀",
    },
    {
      phase: "Phase 2: Community Growth",
      description: "Engage users through contests and token listings on DEXs.",
      icon: "🌐",
    },
    {
      phase: "Phase 3: Utility Expansion",
      description: "Introduce staking and NFT integrations.",
      icon: "🔗",
    },
    {
      phase: "Phase 4: Scale and Partnerships",
      description: "Partner with brands and expand to multi-chain ecosystems.",
      icon: "🤝",
    },
  ];

  return (
    <section className="roadmap">
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="roadmap-title"
      >
        Project Roadmap
      </motion.h2>
      <div className="roadmap-steps">
        {roadmapData.map((step, index) => (
          <motion.div
            key={index}
            className="step"
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <div className="icon">{step.icon}</div>
            <h3>{step.phase}</h3>
            <p>{step.description}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Roadmap;
