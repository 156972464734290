import React from "react";
import {
  FaWallet,
  FaConnectdevelop,
  FaPlay,
  FaCheckCircle,
} from "react-icons/fa";
import "./Mining.css";

const MiningInstructions = () => {
  return (
    <section className="mining-instructions">
      <h2>How to Mine PotusSol</h2>
      <ul className="instruction-list">
        <li>
          <FaWallet className="instruction-icon" />
          <div>
            <strong>Step 1:</strong> Install a wallet like MetaMask or Trust Wallet on your device.
          </div>
        </li>
        <li>
          <FaConnectdevelop className="instruction-icon" />
          <div>
            <strong>Step 2:</strong> Connect your wallet to our platform by clicking the "Connect Wallet" button below.
          </div>
        </li>
        <li>
          <FaPlay className="instruction-icon" />
          <div>
            <strong>Step 3:</strong> Click "Start Mining" to begin earning PotusSol tokens.
          </div>
        </li>
        <li>
          <FaCheckCircle className="instruction-icon" />
          <div>
            <strong>Step 4:</strong> Once mining is complete, tokens will be added to your wallet.
          </div>
        </li>
      </ul>
      <p className="instruction-note">
        Note: Wallet connection functionality and token transfers will be enabled soon. Stay tuned for updates!
      </p>
    </section>
  );
};

export default MiningInstructions;
