import React from "react";
import { FaTimes, FaCoins, FaUsers, FaCalendarAlt, FaInfoCircle } from "react-icons/fa";
import "./AirdropModal.css";

const AirdropModal = ({ airdrop, onClose }) => {
  const handleAction = (action) => {
  };

  return (
    <div className="airdrop-modal">
      <div className="modal-content">
        {/* Close Button */}
        <button className="close-btn" onClick={onClose}>
          <FaTimes />
        </button>

        {/* Modal Header */}
        <div className="modal-header">
          <FaInfoCircle className="modal-header-icon" />
          <h3 className="modal-title">{airdrop.name || "Unnamed Airdrop"}</h3>
        </div>

        {/* Modal Body */}
        <div className="modal-body">
          <div className="modal-item">
            <FaCoins className="modal-icon" />
            <p><strong>Total Amount:</strong> {airdrop.totalAmount}</p>
          </div>
          <div className="modal-item">
            <FaCoins className="modal-icon" />
            <p><strong>Remaining Amount:</strong> {airdrop.remainingAmount}</p>
          </div>
          <div className="modal-item">
            <FaUsers className="modal-icon" />
            <p><strong>Max Participants:</strong> {airdrop.maxParticipants}</p>
          </div>
          <div className="modal-item">
            <FaInfoCircle className="modal-icon" />
            <p><strong>Status:</strong> {airdrop.status}</p>
          </div>
          <div className="modal-item">
            <FaCalendarAlt className="modal-icon" />
            <p><strong>Expiry Date:</strong> {new Date(airdrop.expiryDate).toLocaleString()}</p>
          </div>
          <div className="modal-item">
            <FaInfoCircle className="modal-icon" />
            <p><strong>Criteria:</strong> {airdrop.criteria || "None"}</p>
          </div>
        </div>

        {/* Modal Footer */}
        <div className="modal-footer">
          <button onClick={() => handleAction("Stop")} className="action-btn stop-btn">
            Stop
          </button>
          <button onClick={() => handleAction("Restart")} className="action-btn restart-btn">
            Restart
          </button>
        </div>
      </div>
    </div>
  );
};

export default AirdropModal;
