import React, { useEffect } from "react";
import Hero from "../components/Hero/Hero";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Roadmap from "../components/Roadmap/Roadmap";
import CountdownWidget from "../components/CountdownWidget/CountdownWidget";
import { useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if navigation state requests a scroll to the roadmap
    if (location.state?.scrollToRoadmap) {
      const roadmapSection = document.getElementById("roadmap");
      if (roadmapSection) {
        roadmapSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div>
      <Header />
      <Hero />
      <CountdownWidget /> {/* Add the widget here */}
      <section id="roadmap">
        <Roadmap />
      </section>
      <Footer />
    </div>
  );
};

export default Home;
