import React from "react";
import { motion } from "framer-motion";
import { loadFull } from "tsparticles";
import { Particles } from "react-tsparticles";
import FooterBrand from "./FooterBrand";
import FooterLinks from "./FooterLinks";
import FooterSocial from "./FooterSocial";
import FooterBottom from "./FooterBottom";
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const particlesInit = async (main) => {
    await loadFull(main); // Load full particles configuration
  };

  const particlesOptions = {
    fullScreen: false, // Constrain particles to the footer container
    background: {
      color: {
        value: "transparent", // Ensure the footer's background shows through
      },
    },
    fpsLimit: 60,
    particles: {
      number: {
        value: 100,
        density: {
          enable: true,
          area: 800,
        },
      },
      color: {
        value: ["#61dafb", "#21a1f1", "#f7931a", "#9945ff"],
      },
      shape: {
        type: "circle",
      },
      opacity: {
        value: 0.5,
      },
      size: {
        value: 3,
        random: true,
      },
      move: {
        enable: true,
        speed: 1.5,
        direction: "none",
        random: false,
        straight: false,
        outMode: "out",
      },
    },
    interactivity: {
      events: {
        onHover: {
          enable: true,
          mode: "repulse",
        },
        onClick: {
          enable: true,
          mode: "push",
        },
      },
      modes: {
        repulse: {
          distance: 100,
          duration: 0.4,
        },
        push: {
          quantity: 4,
        },
      },
    },
    detectRetina: true,
  };

  return (
    <footer className="footer">
      <div className="footer-particles-container">
        <Particles
          id="tsparticles-footer"
          init={particlesInit}
          options={particlesOptions}
        />
      </div>
      <motion.div
        className="footer-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <FooterBrand />
        <FooterLinks />
        <FooterSocial />
      </motion.div>
      <FooterBottom currentYear={currentYear} />
    </footer>
  );
};

export default Footer;
