import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import MiningHero from "./MiningHero";
import MiningStats from "./MiningStats";
import MiningInstructions from "./MiningInstructions";
import "./Mining.css";

const Mining = () => {
  return (
    <div className="mining-page">
      <Header />
      <MiningHero />
      <MiningStats />
      <MiningInstructions />
      <Footer />
    </div>
  );
};

export default Mining;
