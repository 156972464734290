import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import AirdropDetails from "./AirdropDetails";
import AirdropCreate from "./AirdropCreate";
import AirdropList from "./AirdropList";
import axios from "axios";
import "./Css/Airdrop.css";

const Airdrop = () => {
  const [airdrops, setAirdrops] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const fetchAirdrops = async () => {
    try {
      const userWallet = localStorage.getItem("userWallet");
      if (!userWallet) throw new Error("User wallet not found");

      const backendUrl = `${process.env.REACT_APP_BACKEND_URL}/api/airdrop/by-owner/${userWallet}`;
      const response = await axios.get(backendUrl);

      const fetchedAirdrops = response.data;
      setAirdrops(fetchedAirdrops);

      // Update localStorage
      localStorage.setItem("airdrops", JSON.stringify(fetchedAirdrops));

      // Automatically set the first active airdrop
      const activeAirdrop = fetchedAirdrops.find((airdrop) => airdrop.status === "active");
      if (activeAirdrop) {
        localStorage.setItem("selectedAirdrop", JSON.stringify(activeAirdrop));
      }
    } catch (err) {
      setError(err.response?.data?.error || "Failed to fetch airdrops. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAirdrops();

    // Periodic updates
    const interval = setInterval(fetchAirdrops, 90000); // Every 90 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // Stagger child animations
      },
    },
  };

  const childVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <motion.div
      className="airdrop-row"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div id="airdrop-list" className="airdrop-list" variants={childVariants}>
        <AirdropList airdrops={airdrops} loading={loading} error={error} />
      </motion.div>
      <motion.div id="airdrop-create" className="airdrop-create" variants={childVariants}>
        <AirdropCreate refreshAirdrops={fetchAirdrops} />
      </motion.div>
      <motion.div id="airdrop-details" className="airdrop-details" variants={childVariants}>
        <AirdropDetails airdrops={airdrops} />
      </motion.div>
    </motion.div>
  );
};

export default Airdrop;
