import React, { useContext, useEffect, useState } from "react";
import { WalletContext } from "../../contexts/WalletContext";
import { GiToken } from "react-icons/gi"; // Token icon
import { MdOutlineStackedLineChart } from "react-icons/md"; // Circulating supply icon
import { motion } from "framer-motion"; // For animations
import "./TotalSupply.css";

const TotalSupply = () => {
  const { web3 } = useContext(WalletContext);
  const [totalSupply, setTotalSupply] = useState(0);
  const [circulatingSupply, setCirculatingSupply] = useState(0);

  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const abi = [
    {
      constant: true,
      inputs: [],
      name: "totalSupply",
      outputs: [{ name: "", type: "uint256" }],
      type: "function",
    },
  ];

  useEffect(() => {
    const fetchSupplyData = async () => {
      if (web3) {
        try {
          const contract = new web3.eth.Contract(abi, contractAddress);
          const supply = await contract.methods.totalSupply().call();
          const supplyInEther = web3.utils.fromWei(supply, "ether");

          setTotalSupply(supplyInEther);

          // Simulate circulating supply as 90% of total for demonstration
          setCirculatingSupply((supplyInEther * 90) / 100);
        } catch (error) {
          console.error("Error fetching supply data:", error);
        }
      }
    };

    fetchSupplyData();
  }, [web3]);

  return (
    <motion.div
      className="total-supply-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <h2 className="supply-title">
        <GiToken className="title-icon" /> Total Supply
      </h2>
      <div className="supply-details">
        <motion.div
          className="supply-card"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <GiToken className="supply-icon" />
          <p>Total Supply</p>
          <span>{totalSupply || "Loading..."} POTUS</span>
        </motion.div>
        <motion.div
          className="supply-card"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <MdOutlineStackedLineChart className="supply-icon" />
          <p>Circulating Supply</p>
          <span>{circulatingSupply || "Loading..."} POTUS</span>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default TotalSupply;
