import React, { createContext, useState, useEffect } from "react";
import Web3 from "web3";
import PotusSolABI from "../abis/PotusSol.json";

export const WalletContext = createContext();

const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(localStorage.getItem("walletAddress") || null);
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("disconnected"); // 'disconnected', 'connecting', 'connected'

  const connectWallet = async () => {
    setConnectionStatus("connecting");
    if (window.ethereum) {
      try {
        const web3Instance = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        setWalletAddress(accounts[0]);
        localStorage.setItem("walletAddress", accounts[0]); // Store wallet address in localStorage
        setWeb3(web3Instance);

        const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
        if (!Web3.utils.isAddress(contractAddress)) {
          throw new Error(`Invalid Ethereum address: ${contractAddress}`);
        }

        const contractInstance = new web3Instance.eth.Contract(PotusSolABI.abi, contractAddress);
        setContract(contractInstance);
        setConnectionStatus("connected");
      } catch (error) {
        console.error("Wallet connection failed:", error);
        setConnectionStatus("disconnected");
      }
    } else {
      // No wallet detected
      setConnectionStatus("disconnected");
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        // Guide for mobile users
        if (window.confirm("No wallet detected. Would you like to install a mobile wallet?")) {
          window.location.href = "https://metamask.app.link/dapp/potussol.org/";
        }
      } else {
        // Guide for desktop users
        alert(
          "MetaMask is not installed. Please install it from https://metamask.io/download.html and try again."
        );
      }
    }
  };

  useEffect(() => {
    // Restore wallet connection state on page load
    if (walletAddress) {
      setConnectionStatus("connected");
    }

    // Listen for wallet account changes
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length === 0) {
          // Wallet disconnected
          setWalletAddress(null);
          setConnectionStatus("disconnected");
          localStorage.removeItem("walletAddress");
        } else {
          // Wallet switched
          setWalletAddress(accounts[0]);
          localStorage.setItem("walletAddress", accounts[0]);
          setConnectionStatus("connected");
        }
      });
    }

    // Load local provider (fallback)
    const loadLocalProvider = async () => {
      const localProviderUrl = process.env.REACT_APP_LOCAL_RPC_URL;
      if (!localProviderUrl) {
        console.error("Local RPC URL not found in environment variables.");
        return;
      }

      const web3Instance = new Web3(localProviderUrl);
      setWeb3(web3Instance);

      const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
      if (!Web3.utils.isAddress(contractAddress)) {
        console.error(`Invalid Ethereum address: ${contractAddress}`);
        return;
      }

      const contractInstance = new web3Instance.eth.Contract(PotusSolABI.abi, contractAddress);
      setContract(contractInstance);
    };

    loadLocalProvider();
  }, [walletAddress]);

  return (
    <WalletContext.Provider
      value={{ walletAddress, web3, contract, connectWallet, connectionStatus }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletProvider;
