const PlansData = [
    {
      title: "Staking Mechanism",
      description: "Allow users to lock their tokens for a specified period to earn rewards.",
      features: [
        "Lock Period: Users can specify a lock duration to earn rewards.",
        "Reward Calculation: Rewards based on staked amount and duration.",
        "Unstaking Mechanism: Users can withdraw their staked tokens after the lock period."
      ]
    },
    {
      title: "Voting and Governance",
      description: "Introduce a governance model where token holders can vote on proposals.",
      features: [
        "Proposal Submission: Users can submit proposals.",
        "Voting Power: Voting weight based on token balance.",
        "Execution: Execute decisions if votes exceed a threshold."
      ]
    },
    {
      title: "Referral Program",
      description: "Reward users for bringing others to the ecosystem.",
      features: [
        "Referral Tracking: Unique referral codes.",
        "Bonus Distribution: A percentage of tokens for both referrer and referee."
      ]
    },
    {
      title: "Time-Locked Wallets",
      description: "Introduce vesting schedules for team members or investors.",
      features: [
        "Vesting Periods: Tokens are released gradually over time.",
        "Cliff Period: Initial lock before tokens are accessible.",
        "Emergency Withdrawal: Owners can cancel vesting if necessary."
      ]
    },
    {
      title: "Buyback and Burn",
      description: "Mechanism to buy back tokens from the market and burn them to reduce supply.",
      features: [
        "Automatic Buybacks: Trigger buybacks based on treasury balance.",
        "Burn Rate: Define a percentage of tokens bought back that should be burned."
      ]
    },
    {
      title: "Dynamic Transaction Fees",
      description: "Adjust transaction fees dynamically based on market conditions.",
      features: [
        "Fee Tiers: Lower fees for large holders or specific transactions.",
        "Dynamic Adjustment: Fees decrease as token price stabilizes."
      ]
    },
    {
      title: "NFT Integration",
      description: "Add support for NFTs to expand your ecosystem.",
      features: [
        "Minting NFTs: Use tokens to mint NFTs.",
        "Staking NFTs: Earn rewards for staking NFTs.",
        "NFT-Based Governance: Special voting rights for NFT holders."
      ]
    },
    {
      title: "Cross-Chain Bridge",
      description: "Enable token transfer across different blockchains.",
      features: [
        "Bridge Contracts: Manage token swaps between chains.",
        "Wrapped Tokens: Mint wrapped versions of tokens on the other chain."
      ]
    },
    {
      title: "Anti-Whale Mechanism",
      description: "Prevent large holders from manipulating the market.",
      features: [
        "Transaction Limits: Cap the maximum number of tokens a user can transfer in a single transaction.",
        "Holder Tax: Higher fees for accounts holding more than a certain percentage of the supply."
      ]
    },
    {
      title: "Dynamic Reward Pools",
      description: "Introduce dynamic reward pools for various activities.",
      features: [
        "Activity-Based Rewards: Earn tokens for trading, staking, or holding.",
        "Leaderboard Incentives: Extra rewards for top participants."
      ]
    },
    {
      title: "Deflationary Mechanisms",
      description: "Implement additional ways to reduce token supply.",
      features: [
        "Burn on Transfer: Burn a percentage of tokens on every transaction.",
        "Lottery Burns: A percentage of lottery funds goes to burning tokens.",
        "Event-Based Burns: Burn tokens during special events."
      ]
    },
    {
      title: "Token Insurance Fund",
      description: "Introduce a mechanism to protect holders from loss due to hacks or exploits.",
      features: [
        "Insurance Pool: A portion of transaction fees goes to an insurance fund.",
        "Claims Process: Users can file claims for losses."
      ]
    },
    {
      title: "Dynamic Airdrops",
      description: "Distribute airdrops dynamically based on user activity.",
      features: [
        "Activity Tracking: Reward based on token holding duration or transaction history.",
        "Fair Distribution: Avoid centralized control by automating the process."
      ]
    },
    {
      title: "Multi-Signature Wallets",
      description: "Enhance security for treasury or large transactions.",
      features: [
        "Multi-Sig Approval: Require multiple signatures for sensitive actions.",
        "Role-Based Access: Only certain accounts can propose actions."
      ]
    },
    {
      title: "Gas Fee Compensation",
      description: "Partially refund users for high gas fees incurred during interactions with the contract.",
      features: [
        "Fee Rebates: Refund tokens equivalent to a percentage of the gas fee.",
        "Activity-Based: More rebates for frequent users."
      ]
    },
    {
      title: "Custom Token Metrics Dashboard",
      description: "Expose on-chain data for analytics and transparency.",
      features: [
        "APIs for Metrics: Supply, burn rate, transaction volume, and more.",
        "Integration: Work with external analytics platforms like Dune Analytics."
      ]
    },
    {
      title: "Upgradeable Contract",
      description: "Implement an upgradeable contract to allow adding features in the future.",
      features: [
        "Proxy Pattern: Use OpenZeppelin’s upgradeable contracts.",
        "Version Control: Keep track of deployed versions."
      ]
    },
    {
      title: "Charity Module",
      description: "Allocate a percentage of fees or supply to charity.",
      features: [
        "Charity Wallet: Automatically send funds to predefined addresses.",
        "Community Voting: Allow token holders to vote on which charities to support."
      ]
    },
    {
      title: "Yield Farming",
      description: "Enable liquidity providers to earn rewards.",
      features: [
        "Liquidity Pools: Pair your token with ETH or other tokens.",
        "Farming Rewards: Distribute tokens to LP providers."
      ]
    },
    {
      title: "Layer-2 Support",
      description: "Optimize for Layer-2 solutions to reduce gas costs.",
      features: [
        "Bridge Contracts: Integrate with Optimism, Arbitrum, or Polygon.",
        "Gas Refunds: Encourage Layer-2 adoption with token rewards."
      ]
    }
  ];
  
  export default PlansData;
  