import React, { useState, useEffect } from "react";
import { WalletContext } from "../../contexts/WalletContext";
import { motion } from "framer-motion"; // For smooth animations
import { FaFire, FaLeaf, FaDollarSign } from "react-icons/fa"; // Web3-inspired icons
import "./TokenBurnMint.css";

const TokenBurnMint = () => {
  const { web3, contract } = React.useContext(WalletContext);
  const [burned, setBurned] = useState(0);
  const [minted, setMinted] = useState(0);
  const [price, setPrice] = useState(0); // Token price

  useEffect(() => {
    const fetchBurnMintData = async () => {
      if (web3 && contract) {
        try {
          const burnedTokens = await contract.methods.totalBurned().call();
          const mintedTokens = await contract.methods.totalMinted().call();

          setBurned(web3.utils.fromWei(burnedTokens, "ether"));
          setMinted(web3.utils.fromWei(mintedTokens, "ether"));
        } catch (error) {
          console.error("Error fetching burn/mint data:", error);
        }
      }
    };

    const fetchPrice = async () => {
      // Fetch token price from a live market API (e.g., CoinGecko, Binance)
      try {
        const response = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=potussol&vs_currencies=usd");
        const data = await response.json();
        setPrice(data.potussol?.usd || 0);
      } catch (error) {
        console.error("Error fetching price data:", error);
      }
    };

    fetchBurnMintData();
    fetchPrice();
  }, [web3, contract]);

  return (
    <div className="burn-mint-metrics">
      <motion.h2 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="burn-mint-title"
      >
        Token Metrics
      </motion.h2>
      <div className="metrics-container">
        {/* Burned Tokens */}
        <motion.div
          className="metric-card burned"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <FaFire className="metric-icon" />
          <p className="metric-label">Burned</p>
          <p className="metric-value">{burned || "Loading..."} POTUS</p>
        </motion.div>

        {/* Minted Tokens */}
        <motion.div
          className="metric-card minted"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <FaLeaf className="metric-icon" />
          <p className="metric-label">Minted</p>
          <p className="metric-value">{minted || "Loading..."} POTUS</p>
        </motion.div>

        {/* Current Price */}
        <motion.div
          className="metric-card price"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <FaDollarSign className="metric-icon" />
          <p className="metric-label">Current Price</p>
          <p className="metric-value">${price || "Loading..."}</p>
        </motion.div>
      </div>
    </div>
  );
};

export default TokenBurnMint;
