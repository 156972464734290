import React, { useState } from "react";
import "./Settings.css";

const DashboardSettings = () => {
  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState(true);

  const toggleNotifications = () => {
    setIsNotificationsEnabled((prev) => !prev);
  };

  return (
    <div className="dashboard-settings">
      <h1>Settings</h1>
      <div className="settings-section">
        <label className="settings-label">
          <input
            type="checkbox"
            checked={isNotificationsEnabled}
            onChange={toggleNotifications}
          />
          Enable Notifications
        </label>
      </div>
      <div className="settings-section">
        <p>Update your preferences and configure your account settings here.</p>
      </div>
    </div>
  );
};

export default DashboardSettings;
