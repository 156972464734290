import React from "react";
import "./Account.css";

const DashboardAccount = () => {
  const user = {
    name: "John Doe",
    email: "john.doe@example.com",
    wallet: "0x1234...abcd",
  };

  return (
    <div className="dashboard-account">
      <h1>Account Details</h1>
      <div className="account-card">
        <p><strong>Name:</strong> {user.name}</p>
        <p><strong>Email:</strong> {user.email}</p>
        <p><strong>Connected Wallet:</strong> {user.wallet}</p>
      </div>
    </div>
  );
};

export default DashboardAccount;
