import React from "react";
import { motion } from "framer-motion";
import { loadFull } from "tsparticles";
import Particles from "react-tsparticles";
import "./Hero.css";

const Hero = () => {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <section className="hero">
      <Particles
        id="hero-tsparticles" // Unique ID for Hero particles
        init={particlesInit}
        options={{
          background: {
            color: {
              value: "#1a1a2e",
            },
          },
          particles: {
            number: {
              value: 100,
            },
            color: {
              value: "#61dafb",
            },
            shape: {
              type: "circle",
            },
            opacity: {
              value: 0.5,
              random: true,
            },
            size: {
              value: 5,
              random: true,
            },
            move: {
              enable: true,
              speed: 2,
              direction: "none",
              random: false,
              straight: false,
              outModes: {
                default: "out",
              },
            },
          },
        }}
      />
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="content"
      >
        <h1 className="hero-title">
          The Future Starts with <span>PotusSol</span>
        </h1>
        <p className="hero-description">
          Empowering communities through <strong>innovation</strong>, <strong>decentralization</strong>, and <strong>limitless possibilities</strong>.
        </p>
        <div className="hero-icons">
          <motion.button
            whileHover={{ scale: 1.1 }}
            className="icon-button buy-button"
          >
            <i className="fas fa-shopping-cart"></i> Buy
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            className="icon-button trade-button"
          >
            <i className="fas fa-exchange-alt"></i> Trade
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            className="icon-button swap-button"
          >
            <i className="fas fa-sync-alt"></i> Swap
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.1 }}
            className="icon-button more-button"
          >
            <i className="fas fa-ellipsis-h"></i> More
          </motion.button>
        </div>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="hero-subtext"
        >
          Join a global movement that bridges technology and community. <br />
          Together, we shape a brighter future.
        </motion.p>
        <motion.button
          whileHover={{ scale: 1.1, boxShadow: "0px 0px 15px #61dafb" }}
          whileTap={{ scale: 0.9 }}
          className="cta-button"
        >
          Discover the Vision
        </motion.button>
      </motion.div>
    </section>
  );
};

export default Hero;
