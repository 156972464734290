import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaUser, FaEnvelope, FaFileAlt, FaDollarSign, FaPaperPlane, FaSpinner } from "react-icons/fa";
import "./SubmitProposal.css";

const SubmitProposal = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [proposal, setProposal] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
  
    const proposalData = {
      name,
      email,
      proposal,
      amount,
    };
  
  
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/proposal`; // Construct the full URL

  
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(proposalData),
      });
  
  
      if (response.ok) {
        const data = await response.json();
        setMessage("Proposal submitted successfully! We will get in touch soon.");
        setName("");
        setEmail("");
        setProposal("");
        setAmount("");
      } else {
        const errorData = await response.json();
        console.error("Server Error Response:", errorData);
        setMessage(errorData.error || "Failed to submit the proposal. Please try again later.");
      }
    } catch (error) {
      console.error("Error submitting proposal:", error);
      setMessage("Unexpected error occurred. Please contact support.");
    }
  
    setLoading(false);
};

  

  return (
    <motion.section
      className="submit-proposal"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <motion.h2
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="proposal-title"
      >
        Submit Your Investment Proposal
      </motion.h2>
      <motion.p
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
        className="proposal-description"
      >
        Share your investment idea and the amount you'd like to contribute to PotusSol. Our team will review your proposal and contact you.
      </motion.p>
      <form className="proposal-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">
            <FaUser className="form-icon" /> Name
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">
            <FaEnvelope className="form-icon" /> Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="proposal">
            <FaFileAlt className="form-icon" /> Proposal Details
          </label>
          <textarea
            id="proposal"
            value={proposal}
            onChange={(e) => setProposal(e.target.value)}
            rows="5"
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="amount">
            <FaDollarSign className="form-icon" /> Amount ($)
          </label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <motion.button
          type="submit"
          className="submit-button"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          disabled={loading}
        >
          {loading ? (
            <>
              <FaSpinner className="button-icon spinner" /> Submitting...
            </>
          ) : (
            <>
              <FaPaperPlane className="button-icon" /> Submit Proposal
            </>
          )}
        </motion.button>
        {message && <p className="form-message">{message}</p>}
      </form>
    </motion.section>
  );
};

export default SubmitProposal;
