import { useContext, useEffect, useState } from "react";
import { WalletContext } from "../../contexts/WalletContext";

const useDashboardLogic = () => {
  const { walletAddress, contract, connectWallet } = useContext(WalletContext);
  const [balance, setBalance] = useState(0);

  // Fetch token balance
  const fetchBalance = async () => {
    if (contract && walletAddress) {
      try {
        const balance = await contract.methods.balanceOf(walletAddress).call();
        setBalance(balance);
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    }
  };

  // Logout logic
  const logout = () => {
    localStorage.removeItem("lastVisitedPath"); // Clear saved path
    localStorage.removeItem("userSession"); // Clear user session
    window.location.href = "/"; // Redirect to the home page
  };

  // Navigation logic with persistence
  const navigateTo = (section) => {
    localStorage.setItem("lastVisitedPath", `/dashboard/${section}`); // Save current section to local storage
    window.location.href = `/dashboard/${section}`; // Redirect to the specific section
  };

  useEffect(() => {
    fetchBalance();
  }, [contract, walletAddress]);

  return {
    walletAddress,
    balance,
    connectWallet,
    logout,
    navigateTo,
  };
};

export default useDashboardLogic;
