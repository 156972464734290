import React from "react";
import { motion } from "framer-motion";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import InvestmentDetails from "./InvestmentDetails";
import ProfitCalculator from "./ProfitCalculator";
import SubmitProposal from "./SubmitProposal"; // Importing the SubmitProposal component
import "./Invest.css";

const Invest = () => {
  return (
    <>
      <Header />
      <motion.div
        className="invest-page"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        {/* Hero Section */}
        <motion.section
          className="invest-hero"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.h1
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            Invest in PotusSol
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: 0.4 }}
          >
            Secure your future by investing in PotusSol. Join us on our journey
            to revolutionize blockchain and decentralized finance.
          </motion.p>
        </motion.section>

        {/* Investment Details Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.6 }}
        >
          <InvestmentDetails />
        </motion.div>

        {/* Profit Calculator Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.8 }}
        >
          <ProfitCalculator />
        </motion.div>

        {/* Submit Proposal Section */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <SubmitProposal />
        </motion.div>
      </motion.div>

      <Footer />
    </>
  );
};

export default Invest;
