import React, { useState, useEffect, useMemo } from "react";
import { FaClock, FaCalendarAlt, FaTools } from "react-icons/fa";
import { Link } from "react-router-dom"; // To link to the mining page
import "./CountdownWidget.css";

const CountdownWidget = () => {
  const launchDate = useMemo(() => new Date("2025-05-15T00:00:00"), []); // Memoized launch date
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = launchDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setTimeLeft(null); // Countdown is over
      }
    };

    const interval = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [launchDate]);

  return (
    <div className="countdown-widget">
      <h2 className="widget-title">
        <FaClock className="widget-icon" /> Token Launch Countdown
      </h2>
      {timeLeft ? (
        <div>
          <div className="countdown-timer">
            <div className="time-segment">
              <span className="time">{timeLeft.days}</span>
              <span className="label">Days</span>
            </div>
            <div className="time-segment">
              <span className="time">{timeLeft.hours}</span>
              <span className="label">Hours</span>
            </div>
            <div className="time-segment">
              <span className="time">{timeLeft.minutes}</span>
              <span className="label">Minutes</span>
            </div>
            <div className="time-segment">
              <span className="time">{timeLeft.seconds}</span>
              <span className="label">Seconds</span>
            </div>
          </div>

          <div className="mining-info">
            <p>Be among the first to mine PotusSol tokens after the launch!</p>
            <Link to="/mining" className="mine-button">
              <FaTools className="button-icon" /> Start Mining
            </Link>
          </div>
        </div>
      ) : (
        <h3 className="launch-message">
          <FaCalendarAlt className="widget-icon" /> The Token Has Launched!
        </h3>
      )}
    </div>
  );
};

export default CountdownWidget;
