import React, { useState } from "react";
import AirdropModal from "../Modal/AirdropModal";
import { FaInfoCircle } from "react-icons/fa";
import "./Css/AirdropDetails.css";

const AirdropDetails = ({ airdrops }) => {
  const [selectedAirdrop, setSelectedAirdrop] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleViewDetails = (airdrop) => {
    setSelectedAirdrop(airdrop);
    setShowModal(true);
  };

  return (
    <div className="airdrop-detail">
      <h2 className="details-title">
        <FaInfoCircle className="details-icon" />
        Your Airdrops
      </h2>

      {airdrops.length === 0 ? (
        <p className="no-data-message">No active airdrops available.</p>
      ) : (
        airdrops.map((airdrop) => (
          <div key={airdrop.id} className="airdrop-card">
            <div className="airdrop-content">
              <FaInfoCircle className="airdrop-icon" />
              <div className="airdrop-info">
                <span className="airdrop-name">{airdrop.name || "Unnamed Airdrop"}</span>
                <span className="airdrop-participants">Participants: {airdrop.maxParticipants}</span>
              </div>
            </div>
            <button
              onClick={() => handleViewDetails(airdrop)}
              className="view-btn"
            >
              View
            </button>
          </div>
        ))
      )}

      {showModal && selectedAirdrop && (
        <AirdropModal
          airdrop={selectedAirdrop}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default AirdropDetails;
