import React from "react";
import { motion } from "framer-motion";
import { FaRocket, FaGlobe, FaHeart, FaShieldAlt, FaHandshake } from "react-icons/fa";
import "./FooterBrand.css";

const FooterBrand = () => (
  <div className="footer-brand">
    <motion.h1
      className="footer-brand-title"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1 }}
    >
      PotusSol
    </motion.h1>
    <motion.p
      className="footer-brand-tagline"
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 1, delay: 0.2 }}
    >
      Redefining possibilities through decentralization, innovation, and community empowerment.
    </motion.p>
    <motion.div
      className="footer-brand-icons"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: 0.4 }}
    >
      <div className="brand-icon">
        <FaRocket className="icon innovation" />
        <span>Innovation</span>
      </div>
      <div className="brand-icon">
        <FaGlobe className="icon global" />
        <span>Global Reach</span>
      </div>
      <div className="brand-icon">
        <FaShieldAlt className="icon security" />
        <span>Security</span>
      </div>
      <div className="brand-icon">
        <FaHandshake className="icon partnerships" />
        <span>Partnerships</span>
      </div>
    </motion.div>
  </div>
);

export default FooterBrand;
