import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll"; // Smooth scrolling for Home page
import Modal from "../Modal/Modal"; // Import Modal
import LoginForm from "../../Auth/Login"; // Import Login Form
import "./Header.css";

const Header = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    // Ensure dynamic background adaptation for the header (if needed)
    const pageBackground = document.body.style.backgroundColor || getComputedStyle(document.body).backgroundColor;
    document.documentElement.style.setProperty('--header-bg-color', pageBackground);
  }, [location]);

  return (
    <>
      <header className="header">
        <nav className="nav">
          <ul className="nav-list">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
              >
                <i className="fas fa-home"></i>
                <span>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/tokenomics"
                className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
              >
                <i className="fas fa-chart-pie"></i>
                <span>Tokenomics</span>
              </NavLink>
            </li>
            <li>
              {isHomePage ? (
                <ScrollLink
                  to="roadmap"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="nav-link"
                >
                  <i className="fas fa-map"></i>
                  <span>Roadmap</span>
                </ScrollLink>
              ) : (
                <NavLink
                  to="/"
                  className="nav-link"
                  state={{ scrollToRoadmap: true }}
                >
                  <i className="fas fa-map"></i>
                  <span>Roadmap</span>
                </NavLink>
              )}
            </li>
            <li>
              <NavLink
                to="/about"
                className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
              >
                <i className="fas fa-info-circle"></i>
                <span>About</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/faqs"
                className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
              >
                <i className="fas fa-question-circle"></i>
                <span>FAQs</span>
              </NavLink>
            </li>
            <li>
              <button className="login-button" onClick={openModal}>
                <i className="fas fa-sign-in-alt"></i>
                <span>Login</span>
              </button>
            </li>
          </ul>
        </nav>
      </header>

      {/* Render Modal */}
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <LoginForm />
        </Modal>
      )}
    </>
  );
};

export default Header;
