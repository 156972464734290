import React from "react";
import { motion } from "framer-motion";
import { FaHandshake, FaCoins, FaChartLine, FaUsers, FaGavel, FaWallet, FaEye, FaMoneyCheck } from "react-icons/fa";
import InvestmentDetailsData from "../../utils/InvestmentDetailsData";
import "./InvestmentDetails.css";

const iconMapping = {
  FaHandshake: <FaHandshake />,
  FaCoins: <FaCoins />,
  FaChartLine: <FaChartLine />,
  FaUsers: <FaUsers />,
  FaGavel: <FaGavel />,
  FaWallet: <FaWallet />,
  FaEye: <FaEye />,
  FaMoneyCheck: <FaMoneyCheck />,
};

const InvestmentDetails = () => {
  const { title, intro, benefits, keyFeaturesTitle, features, summary } = InvestmentDetailsData;

  return (
    <motion.section
      className="investment-details"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      {/* Title */}
      <motion.h2
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="investment-title"
      >
        {title}
      </motion.h2>

      {/* Introduction */}
      <motion.p
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
        className="investment-intro"
      >
        {intro}
      </motion.p>

      {/* Benefits */}
      <ul className="investment-benefits">
        {benefits.map((benefit, index) => (
          <motion.li
            key={index}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >
            <span className="benefit-icon">{iconMapping[benefit.icon]}</span> {benefit.text}
          </motion.li>
        ))}
      </ul>

      {/* Key Features */}
      <motion.h3
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
        className="key-features-title"
      >
        {keyFeaturesTitle}
      </motion.h3>

      <ul className="key-features">
        {features.map((feature, index) => (
          <motion.li
            key={index}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
          >
            <span className="feature-icon">{iconMapping[feature.icon]}</span> <strong>{feature.title}</strong>{" "}
            {feature.description}
          </motion.li>
        ))}
      </ul>

      {/* Summary */}
      <motion.p
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.8 }}
        className="investment-summary"
      >
        {summary}
      </motion.p>
    </motion.section>
  );
};

export default InvestmentDetails;
