import React, { useState } from "react";
import axios from "axios";
import { FaCoins, FaUsers, FaClipboardList, FaRandom, FaCalendarAlt, FaTag } from "react-icons/fa";
import Notification from "../Notification";
import "./Css/AirdropCreate.css";

const AirdropCreate = () => {
  const [form, setForm] = useState({
    name: "",
    totalAmount: "",
    maxParticipants: "",
    criteria: "",
    randomValueRange: "",
    expiryDate: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess(false);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/airdrop/create`,
        {
          ...form,
          owner: localStorage.getItem("userWallet"),
        }
      );
      setSuccess("Airdrop created successfully!");
      setForm({
        name: "",
        totalAmount: "",
        maxParticipants: "",
        criteria: "",
        randomValueRange: "",
        expiryDate: "",
      });
    } catch (error) {
      setError(error.response?.data?.error || "Failed to create airdrop");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="airdrop-creat">
      <h2>Create Airdrop</h2>
      <form onSubmit={handleSubmit} className="airdrop-form">
        <div className="form-group">
          <FaTag className="form-icon" />
          <input
            name="name"
            type="text"
            placeholder="Airdrop Name"
            value={form.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <FaCoins className="form-icon" />
          <input
            name="totalAmount"
            type="number"
            placeholder="Total Amount"
            value={form.totalAmount}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <FaUsers className="form-icon" />
          <input
            name="maxParticipants"
            type="number"
            placeholder="Max Participants"
            value={form.maxParticipants}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <FaClipboardList className="form-icon" />
          <input
            name="criteria"
            type="text"
            placeholder="Criteria"
            value={form.criteria}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <FaRandom className="form-icon" />
          <input
            name="randomValueRange"
            type="text"
            placeholder="Random Range (e.g., 10-50)"
            value={form.randomValueRange}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <FaCalendarAlt className="form-icon" />
          <input
            name="expiryDate"
            type="date"
            value={form.expiryDate}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-btn" disabled={loading}>
          {loading ? "Creating..." : "Create Airdrop"}
        </button>
      </form>

      {success && <Notification type="success" message={success} />}
      {error && <Notification type="error" message={error} />}
    </div>
  );
};

export default AirdropCreate;
