import React from "react";
import { NavLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { motion } from "framer-motion";

const FooterLinks = () => {
  const location = window.location.pathname;
  const isHomePage = location === "/";

  return (
    <div className="footer-links">
      <h3>Quick Links</h3>
      <ul>
        <motion.li whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
          <a href="/about">
            <i className="fas fa-info-circle"></i> About Us
          </a>
        </motion.li>
        <motion.li whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
          <a href="/tokenomics">
            <i className="fas fa-chart-pie"></i> Tokenomics
          </a>
        </motion.li>
        {isHomePage ? (
          <motion.li whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
            <ScrollLink
              to="roadmap"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <i className="fas fa-map"></i> Roadmap
            </ScrollLink>
          </motion.li>
        ) : (
          <motion.li whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
            <NavLink to="/" state={{ scrollToRoadmap: true }}>
              <i className="fas fa-map"></i> Roadmap
            </NavLink>
          </motion.li>
        )}
        <motion.li whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
          <a href="/faqs">
            <i className="fas fa-question-circle"></i> FAQs
          </a>
        </motion.li>
        <motion.li whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
          <a href="/plans">
            <i className="fas fa-lightbulb"></i> Future Plans
          </a>
        </motion.li>
        <motion.li whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
          <a href="/sitemap.xml" target="_blank" rel="noopener noreferrer">
            <i className="fas fa-sitemap"></i> Sitemap
          </a>
        </motion.li>
        <motion.li whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
          <a href="/invest">
            <i className="fas fa-chart-line"></i> Invest
          </a>
        </motion.li>
      </ul>
    </div>
  );
};

export default FooterLinks;
