import React from "react";
import PlansData from "../../utils/PlansData";
import { motion } from "framer-motion";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {
  FaLightbulb,
  FaCogs,
  FaLeaf,
  FaWallet,
  FaNetworkWired,
  FaChartLine,
  FaHandHoldingHeart,
} from "react-icons/fa";
import "./Plans.css";

const iconMapping = {
  Staking: <FaLeaf />,
  Voting: <FaLightbulb />,
  Referral: <FaChartLine />,
  Vesting: <FaWallet />,
  Buyback: <FaHandHoldingHeart />,
  DynamicFees: <FaCogs />,
  NFT: <FaWallet />,
  CrossChain: <FaNetworkWired />,
  AntiWhale: <FaHandHoldingHeart />,
  RewardPools: <FaChartLine />,
  Deflationary: <FaCogs />,
  Insurance: <FaLeaf />,
  Airdrops: <FaWallet />,
  MultiSig: <FaLightbulb />,
  GasComp: <FaCogs />,
  Dashboard: <FaChartLine />,
  Upgradeable: <FaLightbulb />,
  Charity: <FaHandHoldingHeart />,
  YieldFarming: <FaLeaf />,
  Layer2: <FaNetworkWired />,
};

const Plans = () => {
  return (
    <>
      <Header />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="plans-container"
      >
        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="plans-hero"
        >
          <h1 className="plans-title">Our Visionary Plans</h1>
          <p className="plans-description">
            Explore the roadmap for PotusSol's ecosystem, bringing innovation to blockchain and beyond.
          </p>
        </motion.div>

        {/* Plans Grid */}
        <div className="plans-grid">
          {PlansData.map((plan, index) => (
            <motion.div
              className="plan-card"
              key={index}
              initial={{ opacity: 0, scale: 0.8 }}
              whileHover={{ scale: 1.05 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.4, delay: index * 0.1 }}
            >
              <div className="plan-icon">
                {iconMapping[plan.title.split(" ")[0]] || <FaLightbulb />}
              </div>
              <h2 className="plan-title">{plan.title}</h2>
              <p className="plan-description">{plan.description}</p>
              <ul className="plan-features">
                {plan.features.map((feature, i) => (
                  <li key={i}>{feature}</li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default Plans;
