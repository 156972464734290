import React, { useState } from "react";
import { motion } from "framer-motion"; // For smooth animations
import { FaCoins } from "react-icons/fa"; // Coin icon for input
import { MdCalculate } from "react-icons/md"; // Calculator icon for rewards
import { Ri24HoursFill } from "react-icons/ri"; // Reward icon
import "./Css/StakingCalculator.css";

const StakingCalculator = () => {
  const [amount, setAmount] = useState(0);
  const [reward, setReward] = useState(0);

  const calculateReward = (stakedAmount) => {
    const annualRate = 0.12; // 12% annual staking reward
    setReward((stakedAmount * annualRate).toFixed(2));
  };

  const handleInputChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setAmount(value);
    calculateReward(value);
  };

  return (
    <div className="staking-calculator">
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="staking-title"
      >
        <MdCalculate className="title-icon" /> Staking Calculator
      </motion.h2>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.3 }}
        className="calculator-container"
      >
        <div className="input-container">
          <FaCoins className="input-icon" />
          <input
            type="number"
            value={amount}
            onChange={handleInputChange}
            placeholder="Enter amount to stake"
            className="staking-input"
          />
        </div>
        <div className="reward-container">
          <Ri24HoursFill className="reward-icon" />
          <p className="staking-reward">
            Estimated Annual Reward:{" "}
            <span className="reward-value">{reward || "0.00"} POTUS</span>
          </p>
        </div>
        <p className="staking-note">
          Based on a 12% annual staking reward rate.
        </p>
      </motion.div>
    </div>
  );
};

export default StakingCalculator;
