import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaList, FaInfoCircle } from "react-icons/fa";
import "./Css/AirdropList.css";

const AirdropList = () => {
  const [airdrops, setAirdrops] = useState([]);
  const [error, setError] = useState("");

  // Fetch airdrops from the backend
  const fetchAirdrops = async () => {
    try {
      const backendUrl = `${process.env.REACT_APP_BACKEND_URL}/api/airdrop/by-owner/${localStorage.getItem("userWallet")}`;

      const response = await axios.get(backendUrl);
      const fetchedAirdrops = response.data;

      if (fetchedAirdrops.length > 0) {
        setAirdrops(fetchedAirdrops);
        localStorage.setItem("airdrops", JSON.stringify(fetchedAirdrops));

        // Automatically set the first active airdrop as "selected"
        const activeAirdrop = fetchedAirdrops.find((airdrop) => airdrop.status === "active");
        if (activeAirdrop) {
          localStorage.setItem("selectedAirdrop", JSON.stringify(activeAirdrop));
        }
      } else {
        setAirdrops([]);
        localStorage.removeItem("airdrops");
        localStorage.removeItem("selectedAirdrop");
      }
    } catch (err) {
      console.error("Error fetching airdrops:", err);
      setError(err.response?.data?.error || "Error fetching airdrops. Please try again later.");
    }
  };

  useEffect(() => {
    fetchAirdrops();

    // Schedule periodic updates
    const interval = setInterval(() => {
      fetchAirdrops();
    }, 90000); // Every 90 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div className="airdrop-lis">
      <h2>
        <FaList className="list-icon" />
        Airdrop List
      </h2>

      {airdrops.length === 0 && !error && (
        <p className="no-airdrops-message">
          You currently have no airdrops. Start by creating one!
        </p>
      )}

      {error && (
        <p className="error-message">
          <FaInfoCircle className="error-icon" />
          {error}
        </p>
      )}

      <ul className="airdrop-list-items">
        {airdrops.map((airdrop) => (
          <li key={airdrop.id} className="airdrop-item">
            <FaInfoCircle className="airdrop-icon" />
            {/* Display name and maxParticipants */}
            <strong>{airdrop.name || "Unnamed Airdrop"}</strong> (Participants: {airdrop.maxParticipants})
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AirdropList;
