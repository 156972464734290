import React from "react";
import { motion } from "framer-motion";
import { FaUsers, FaTools, FaBullhorn, FaUserTie, FaLock } from "react-icons/fa";
import "./TokenAllocation.css";

const TokenAllocation = () => {
  const allocationData = [
    { label: "Community", percentage: 40, icon: <FaUsers />, color: "#61dafb" },
    { label: "Development", percentage: 25, icon: <FaTools />, color: "#21a1f1" },
    { label: "Marketing", percentage: 15, icon: <FaBullhorn />, color: "#ff6f61" },
    { label: "Team", percentage: 10, icon: <FaUserTie />, color: "#ffa500" },
    { label: "Reserve", percentage: 10, icon: <FaLock />, color: "#4caf50" },
  ];

  return (
    <div className="token-allocation-container">
      <h2 className="allocation-title">Token Allocation</h2>
      <div className="allocation-bars">
        {allocationData.map((item, index) => (
          <div key={index} className="allocation-bar-wrapper">
            <div className="allocation-bar-header">
              <div className="allocation-icon">{item.icon}</div>
              <h3>{item.label}</h3>
              <p>{item.percentage}%</p>
            </div>
            <motion.div
              className="allocation-bar-background"
              initial={{ width: 0 }}
              animate={{ width: `${item.percentage}%` }}
              transition={{ duration: 1.5 }}
              style={{ backgroundColor: item.color }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TokenAllocation;
