import React, { useState, useEffect, useContext } from "react";
import { WalletContext } from "../../contexts/WalletContext";
import { FaWallet, FaCogs, FaCoins } from "react-icons/fa";
import "./Mining.css";

const MiningStats = () => {
  const { walletAddress, connectWallet, connectionStatus } = useContext(WalletContext);
  const [miningStatus, setMiningStatus] = useState("Idle");
  const [accumulatedTokens, setAccumulatedTokens] = useState(0);
  const [nextMiningTime, setNextMiningTime] = useState(null);
  const [countdown, setCountdown] = useState("");

  const formatWalletAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 5)}...${address.slice(-5)}`;
  };

  // Fetch mining data
  useEffect(() => {
    const fetchMiningData = async () => {
      if (!walletAddress) return;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/mining/${walletAddress}`
        );
        if (response.ok) {
          const data = await response.json();
          setAccumulatedTokens(data.accumulatedTokens || 0);

          if (data.lastMinedTime) {
            const remainingTime = 4 * 60 * 60 * 1000 - (Date.now() - data.lastMinedTime);
            if (remainingTime > 0) {
              setNextMiningTime(Date.now() + remainingTime);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching mining data:", error);
      }
    };

    fetchMiningData();
  }, [walletAddress]);

  // Countdown timer logic
  useEffect(() => {
    const updateCountdown = () => {
      if (!nextMiningTime) {
        setCountdown("");
        return;
      }

      const remainingTime = nextMiningTime - Date.now();
      if (remainingTime <= 0) {
        setNextMiningTime(null);
        setMiningStatus("Idle");
        setCountdown("");
        return;
      }

      const hours = Math.floor(remainingTime / (1000 * 60 * 60));
      const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

      setCountdown(`${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`);
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [nextMiningTime]);

  const startMining = async () => {
    if (!walletAddress) {
      alert("Please connect your wallet to start mining.");
      return;
    }

    setMiningStatus("Mining...");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/mining/${walletAddress}`,
        { method: "POST" }
      );

      if (response.ok) {
        const data = await response.json();
        setAccumulatedTokens(data.accumulatedTokens);
        setNextMiningTime(Date.now() + 4 * 60 * 60 * 1000); // 4 hours cooldown
        setMiningStatus("Mining Complete");
      } else {
        const error = await response.json();
        alert(error.error || "An error occurred.");
        setMiningStatus("Idle");
      }
    } catch (error) {
      console.error("Error starting mining:", error);
      setMiningStatus("Idle");
    }
  };

  const claimTokens = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/mining/${walletAddress}/claim`,
        { method: "POST" }
      );
  
      if (response.ok) {
        const data = await response.json();
        setAccumulatedTokens(0); // Reset accumulated tokens
        alert(`Successfully claimed ${data.claimedTokens} POTUS.\nTransaction Hash: ${data.transactionHash}`);
      } else {
        const error = await response.json();
        alert(error.error || "An error occurred while claiming tokens.");
      }
    } catch (error) {
      console.error("Error claiming tokens:", error);
    }
  };
  

  return (
    <section className="mining-stats">
      {/* Wallet Status */}
      <div className="stat-card">
        <FaWallet className="stat-icon" />
        <h3>
          Wallet Status:{" "}
          <span
            className={`status-dot ${
              connectionStatus === "connected"
                ? "green-dot"
                : connectionStatus === "connecting"
                ? "yellow-dot"
                : "red-dot"
            }`}
          ></span>
        </h3>
        {walletAddress ? (
          <p>Connected Wallet: {formatWalletAddress(walletAddress)}</p>
        ) : (
          <button onClick={connectWallet} className="connect-wallet-btn">
            <FaWallet /> Connect Wallet
          </button>
        )}
      </div>

      {/* Mining Status */}
      <div className="stat-card">
        <FaCogs className="stat-icon" />
        <h3>Mining Status</h3>
        {nextMiningTime ? (
          <p>Next Mining: {countdown}</p>
        ) : (
          walletAddress &&
          miningStatus === "Idle" && (
            <button onClick={startMining} className="start-mining-btn">
              Start Mining
            </button>
          )
        )}
      </div>

      {/* Accumulated Tokens */}
      <div className="stat-card">
        <FaCoins className="stat-icon" />
        <h3>Accumulated Tokens</h3>
        <p>{accumulatedTokens.toFixed(2)} POTUS</p>
        {accumulatedTokens > 0 && (
          <button onClick={claimTokens} className="claim-tokens-btn">
            Claim Tokens
          </button>
        )}
      </div>
    </section>
  );
};

export default MiningStats;
