import React from "react";
import { motion } from "framer-motion";
import { loadFull } from "tsparticles";
import Particles from "react-tsparticles";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import TotalSupply from "./TotalSupply";
import TokenAllocation from "./TokenAllocation";
import StakingCalculator from "./StakingCalculator";
import TokenBurnMint from "./TokenBurnMint";
import "./Tokenomics.css";

const Tokenomics = () => {
  const heroVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };

  const sectionVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
  };

  const particlesInit = async (main) => {
    await loadFull(main); // Ensure tsparticles loads fully
  };

  const particlesOptions = {
    background: {
      color: {
        value: "transparent", // Transparent background for seamless blending
      },
    },
    particles: {
      number: {
        value: 50,
        density: {
          enable: true,
          area: 800,
        },
      },
      color: {
        value: ["#61dafb", "#21a1f1", "#f7931a", "#9945ff"], // Colors matching theme
      },
      shape: {
        type: "circle",
      },
      opacity: {
        value: 0.5,
      },
      size: {
        value: 3,
        random: true,
      },
      move: {
        enable: true,
        speed: 1.5,
        direction: "none",
        random: false,
        straight: false,
        outMode: "out",
      },
    },
    interactivity: {
      events: {
        onHover: {
          enable: true,
          mode: "repulse",
        },
        onClick: {
          enable: true,
          mode: "push",
        },
      },
      modes: {
        repulse: {
          distance: 100,
          duration: 0.4,
        },
        push: {
          quantity: 4,
        },
      },
    },
    detectRetina: true,
  };

  return (
    <>
      <Header />
      <div className="tokenomics">
        {/* Background Particles */}
        <Particles
          id="tokenomics-tsparticles" // Unique ID for Tokenomics
          init={particlesInit}
          options={particlesOptions}
        />
        {/* Hero Section */}
        <motion.section
          className="tokenomics-hero"
          initial="hidden"
          animate="visible"
          transition={{ duration: 1 }}
          variants={heroVariants}
        >
          <h1 className="hero-title">Tokenomics</h1>
          <p className="hero-description">
            Explore the economic foundation of PotusSol, designed for sustainable growth and community engagement.
          </p>
        </motion.section>

                {/* Token Burn and Mint Metrics */}
                <motion.section
          className="burn-mint-section"
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.8, delay: 0.8 }}
          variants={sectionVariants}
        >
          <h2 className="section-title">Burn & Mint Metrics</h2>
          <TokenBurnMint />
        </motion.section>

        {/* Total Supply Section */}
        <motion.section
          className="total-supply-section"
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.8, delay: 0.2 }}
          variants={sectionVariants}
        >
          <h2 className="section-title">Total Supply</h2>
          <TotalSupply />
        </motion.section>

        {/* Allocation Chart Section */}
        <motion.section
          className="allocation-section"
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.8, delay: 0.4 }}
          variants={sectionVariants}
        >
          <h2 className="section-title">Token Allocation</h2>
          <TokenAllocation />
        </motion.section>

        {/* Staking Calculator Section */}
        <motion.section
          className="staking-section"
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.8, delay: 0.6 }}
          variants={sectionVariants}
        >
          <h2 className="section-title">Staking Calculator</h2>
          <StakingCalculator />
        </motion.section>
      </div>
      <Footer />
    </>
  );
};

export default Tokenomics;
