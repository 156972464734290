import React, { useState } from "react";
import ProfitRates from "../../utils/ProfitRates";
import { motion } from "framer-motion";
import { FaDollarSign, FaCalculator, FaChartLine, FaInfoCircle, FaSpinner } from "react-icons/fa";
import "./ProfitCalculator.css";

const ProfitCalculator = () => {
  const [investment, setInvestment] = useState("");
  const [expectedProfit, setExpectedProfit] = useState(null);
  const [loading, setLoading] = useState(false);

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/,/g, ""); // Remove commas
    if (!isNaN(value)) {
      setInvestment(value);
    }
  };

  const handleCalculate = () => {
    setLoading(true);

    setTimeout(() => {
      const { stakingReward, transactionFeeProfit } = ProfitRates;
      const annualProfit = parseFloat(investment || 0) * (stakingReward + transactionFeeProfit);
      const monthlyProfit = annualProfit / 12;

      setExpectedProfit({
        monthlyProfit: monthlyProfit.toFixed(2),
        breakdown: {
          stakingProfit: (parseFloat(investment || 0) * stakingReward).toFixed(2),
          feeProfit: (parseFloat(investment || 0) * transactionFeeProfit).toFixed(2),
        },
      });

      setLoading(false);
    }, 1000);
  };

  return (
    <motion.section
      className="profit-calculator"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <motion.h2
        className="calculator-title"
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <FaChartLine className="title-icon" /> Calculate Your Monthly Profit
      </motion.h2>
      <motion.div
        className="calculator-container"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <label htmlFor="investment" className="calculator-label">
          <FaDollarSign className="calculator-input-icon" /> Enter Your Investment ($):
        </label>
        <input
          type="text"
          id="investment"
          value={investment ? formatNumber(investment) : ""}
          placeholder="0"
          onChange={handleInputChange}
          className="calculator-input"
        />
        <motion.button
          onClick={handleCalculate}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          disabled={loading || !investment}
          className="calculator-button"
        >
          {loading ? (
            <>
              <FaSpinner className="button-icon spinner" /> Calculating...
            </>
          ) : (
            <>
              <FaCalculator className="calculator-button-icon" /> Calculate
            </>
          )}
        </motion.button>
        {expectedProfit && (
          <motion.div
            className="calculator-results"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            <h3>
              <FaInfoCircle /> Your Estimated Monthly Profit
            </h3>
            <p>
              <strong>Total:</strong> ${formatNumber(expectedProfit.monthlyProfit)}
            </p>
            <h4>Breakdown:</h4>
            <ul>
              <li>
                <strong>Staking Rewards:</strong> ${formatNumber(expectedProfit.breakdown.stakingProfit)}
              </li>
              <li>
                <strong>Transaction Fee Profit:</strong> ${formatNumber(expectedProfit.breakdown.feeProfit)}
              </li>
            </ul>
          </motion.div>
        )}
      </motion.div>
    </motion.section>
  );
};

export default ProfitCalculator;
