import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WalletProvider from "./contexts/WalletContext";
import AuthProvider from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";

import Home from "./pages/Home";
import About from "./components/About/About";
import Tokenomics from "./components/Tokenomics/Tokenomics";
import Roadmap from "./components/Roadmap/Roadmap";
import FAQs from "./components/FAQs/FAQs";
import Mining from "./components/Mining/Mining";
import Invest from "./components/Investment/Invest";
import Login from "./Auth/Login";
import PasswordReset from "./Auth/PasswordReset";
import Dashboard from "./Auth/Dashboard";
import Plans from "./components/Plans/Plans";

import DashboardHome from "./Auth/Home/Home"; // Home page within Dashboard
import DashboardSettings from "./Auth/Settings/Settings"; // Dashboard settings
import DashboardAirdrop from "./Auth/Airdrop/Airdrop"; // Airdrop
import DashboardAccount from "./Auth/Account/Account"; // Account details

function App() {
  return (
    <WalletProvider>
      <Router>
        <AuthProvider>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/tokenomics" element={<Tokenomics />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/mining" element={<Mining />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/invest" element={<Invest />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<PasswordReset />} />

            {/* Private Routes */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            >
              <Route path="home" element={<DashboardHome />} />
              <Route path="settings" element={<DashboardSettings />} />
              <Route path="airdrop" element={<DashboardAirdrop />} />
              <Route path="account" element={<DashboardAccount />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </WalletProvider>
  );
}

export default App;
