import React, { useState } from "react";
import { motion } from "framer-motion";
import { FaqData } from "../../utils/Faq"; // Import FAQ data
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./FAQs.css";

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <Header />
      <div className="faqs">
        {/* Hero Section */}
        <section className="faqs-hero">
          <motion.h1
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="faqs-hero-title"
          >
            Frequently Asked Questions
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
            className="faqs-hero-description"
          >
            Find answers to the most common questions about PotusSol, from its features and security to token purchases and staking rewards.
          </motion.p>
        </section>

        {/* FAQ Section */}
        <section className="faq-content">
          <div className="faq-container">
            {FaqData.map((faq, index) => (
              <motion.div
                key={index}
                className={`faq ${activeIndex === index ? "active" : ""}`}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div
                  className="faq-question"
                  onClick={() => toggleFAQ(index)}
                >
                  <span className="faq-icon">
                    <i
                      className={`fas ${
                        activeIndex === index ? "fa-chevron-up" : "fa-chevron-down"
                      }`}
                    ></i>
                  </span>
                  <span>{faq.question}</span>
                </div>
                <motion.div
                  className="faq-answer"
                  initial={{ height: 0, opacity: 0 }}
                  animate={{
                    height: activeIndex === index ? "auto" : 0,
                    opacity: activeIndex === index ? 1 : 0,
                  }}
                  transition={{ duration: 0.3 }}
                >
                  <p>{faq.answer}</p>
                </motion.div>
              </motion.div>
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default FAQs;
